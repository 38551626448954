import React from "react";
import Container from "./Container";
import Heading from "../Heading";
import { Link } from "react-router-dom";
import {
  PiArrowCircleRightThin,
  PiFacebookLogo,
  PiInstagramLogo,
  PiLinkedinLogo,
} from "react-icons/pi";
import { RiTwitterXLine } from "react-icons/ri";

const Footer = () => {
  return (
    <footer className="pt-20 px-6">
      <Container className="bg-[#6D92B8] text-white rounded-xl lg:rounded-3xl pl-8 lg:pl-16 lg:pr-0 pt-5 lg:pt-10 grid lg:grid-cols-2 overflow-hidden mb-36">
        <div className="max-w-[370px] pr-8">
          <div>
            <Heading className="mb-3">Subscribe to our Newletter</Heading>
            <p className="#EFF3FC">
              Stay updated on new products, special offers, and upcoming sales
              events.
            </p>
          </div>
          <div className="mt-20 flex justify-between pb-3 border-b border-dashed border-white">
            <input
              type="email"
              className="bg-transparent focus:outline-none text-xl placeholder:text-white grow"
              placeholder="Enter your email address"
            />

            <PiArrowCircleRightThin size={32} />
          </div>
        </div>
        <img
          className="block ml-auto"
          src="/images/footer-img.png"
          alt="house"
        />
      </Container>
      <div className="p-0 lg:p-8 text-sm lg:text-base">
        <Container
          variant="lg"
          className="lg:flex w-full justify-between items-center gap-8"
        >
          <img
            src="/svgs/jazz-realties-logo.svg"
            alt="Jazz Realties Logo"
            height={40}
            width={65}
          />
          <div className="flex items-center gap-8 lg:ml-6 my-5 lg:mt-0">
            <Link to="/about">About</Link>
            <Link to="/10ants">10ANTS NG</Link>
            <a href="/#services">Service</a>
            <Link to="/">Contact Us</Link>
          </div>
          <div className="flex items-center gap-6">
            <Link to="/">
              <RiTwitterXLine size={24} />
            </Link>
            <Link to="/">
              <PiFacebookLogo size={24} />
            </Link>
            <Link to="/">
              <PiInstagramLogo size={24} />
            </Link>
            <Link to="/">
              <PiLinkedinLogo size={24} />
            </Link>
          </div>
        </Container>
      </div>
      <div className="border-t border-[#201F2212] text-[#52525B] text-xs lg:text-sm  p-0 pt-4 lg:p-8 mt-10">
        <Container
          variant="lg"
          className="lg:flex w-full lg:justify-between lg:items-center lg:gap-8"
        >
          <p>J.R. LTD© Copyright 2024, All Rights Reserved</p>
          <div className="flex items-center gap-4 lg:gap-8">
            <Link to="/" className="py-4">
              Privacy Policy
            </Link>
            <Link to="/" className="py-4">
              Terms &amp; Conditions
            </Link>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
