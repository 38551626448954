import React from "react";
import WhatWeOffer from "../../components/page-sections/common/WhatWeOffer";
import { Heading, Paragraph } from "../../components";
import { InsightsSection } from "../../components/page-sections/common";
import MissionAndValues from "../../components/page-sections/About/MissionAndValues";
import KeyFactors from "../../components/page-sections/About/KeyFactors";
import TeamSection from "../../components/page-sections/About/TeamSection";
import { Header } from "../../components/layout";

const About = () => {
  return (
    <>
      <Header className="py-[72px] text-center">
        <Heading className="mb-8 mt-40" variant="lg">
          About J.R. LTD
        </Heading>
        <div className="max-w-[1146px] mx-auto">
          <Paragraph>
            J.R. LTD. We are a leading real estate portfolio investment
            management firm, dedicated to helping clients maximize returns on
            their property investments. With over six years of experience, our
            firm specializes in providing expert advice on property
            acquisitions, strategic investment management, and tenant sourcing,
            ensuring optimal returns through tailored solutions like
            buy-to-hold, long-term leasing, or short-term rentals. Our property
            law department, led by a seasoned lawyer with a Master&apos;s in
            Law, offers comprehensive legal support, ensuring your investments
            are protected and compliant with all regulations.
          </Paragraph>
          <Paragraph className="mt-8">
            Additionally, we operate 10ant sNG, our facility management
            subsidiary. 10antsNG utilizes cutting-edge technology through its
            own mobile application to streamline property maintenance and tenant
            relations. Led by a highly experienced Chief Facility Officer,
            qualified in facility management, 10antsNG ensures that properties
            remain in prime condition, enhancing both tenant satisfaction and
            long-term asset value. We are committed to delivering
            results-driven, full-service real estate management with a focus on
            maximizing investment potential for our clients.
          </Paragraph>
        </div>
      </Header>
      <InsightsSection />
      <MissionAndValues />
      <KeyFactors />
      <WhatWeOffer />
      <TeamSection />
    </>
  );
};

export default About;
