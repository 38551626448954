import React, { MouseEventHandler, ReactNode } from "react";

interface ButtonProps {
  className?: string;
  onClick?: MouseEventHandler;
  children: ReactNode;
  variant?: "sm" | "md";
}

const Button = ({ className, onClick, variant = "md", children }: ButtonProps) => {
  return (
    <button
      className={`bg-[#0361CC] text-white rounded-[38px] font-semibold  ${variant === "md" ? "py-4 px-16": "py-3 px-10 text-xs" } ${
        className ? className : ""
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
