import React from "react";
import Heading from "../../Heading";
import Container from "../../layout/Container";
import { Section } from "../../layout";

const MissionAndValues = () => {
  return (
    <Section className="pt-20 pb-16">
      <Container>
        <div className="grid gap-8 md:grid-cols-2 md:gap-0 items-start">
          <div className="flex gap-5 items-center">
            <div className="bg-[#0361CC] rounded-lg w-2 h-16" />
            <Heading>Our Vision</Heading>
          </div>
          <p className="leading-8">
            A vision needs to be explain further and in detail because it is one
            of main point about your future projection and your best goal. The
            company perspective can be seen on this session.
          </p>
        </div>

        <div className="grid gap-8 md:grid-cols-2 md:gap-0 mt-40 items-start">
          <div className="flex gap-5 items-center">
            <div className="bg-[#0361CC] rounded-lg w-2 h-16" />
            <Heading>Our Mission</Heading>
          </div>
          <div>
            <p className="leading-8">
              To empower our clients through strategic real estate investment
              and portfolio management. We are committed to delivering
              exceptional value by leveragintg market insights, inn0vative
              strategies, and sustainable practices.
            </p>
            <p className="leading-8 mt-9">
              Our goal is to maximize returns, minimize risks, and foster
              long-term growth and financial security for our investors. We
              prioritize integrity, transparency, and personalized service,
              ensuring that each client&apos;s unique goals and needs are at the
              forefront of our operations. Together, we build lasting
              partnerships and prosperous futures.
            </p>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default MissionAndValues;
