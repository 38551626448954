import React from "react";
import { VscChevronDown } from "react-icons/vsc";

interface SelectProps {
  className?: string;
  placeholder?: string;
  value?: string;
  label?: string;
  required?: boolean;
}

const Select = ({ className, label, placeholder, required }: SelectProps) => {
  return (
    <div className={`relative ${className ? className : ""}`}>
      {label && (
        <label className="block mb-2 font-medium">
          {label} {required && <span className="text-[#FF0000]">&#42;</span>}
        </label>
      )}
      <select
        className="border border-[#EBEBEB] w-full h-12 px-3 rounded-lg placeholder:text-sm focus:outline-none appearance-none text-[#AEAEAE]"
        required={required}
      >
        <option value={""}>{placeholder}</option>
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
      </select>
      <VscChevronDown className="absolute right-4 bottom-4" color="#AEAEAE" strokeWidth={1} size={12} />
    </div>
  );
};

export default Select;
