import React from "react";
import Heading from "../../Heading";
import Paragraph from "../../Paragraph";
import ImageCarousel from "../../ImageCarousel/ImageCarousel";

const Gallery = () => (
  <section className="py-16 px-6 [&_.swiper-button-prev]:left-[32px]">
    <Heading className="text-center mb-2" variant="lg">
      Gallery
    </Heading>
    <Paragraph className="text-center max-w-[870px] mx-auto mb-10">
      At J.R. LTD our portfolio showcases a diverse range of high-value real
      estate investments, from luxury apartments to exclusive residential
      estates. Each project reflects our commitment to strategic investment,
      meticulous management, and long-term value creation. Explore our featured
      properties to see how we maximize returns for our clients through
      innovative real estate solutions.
    </Paragraph>
    <ImageCarousel />
  </section>
);

export default Gallery;
