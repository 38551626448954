import React from "react";

interface ParagraphProps {
  className?: string;
  children: string;
}

const Paragraph = ({ className, children }: ParagraphProps) => {
  return <p className={`leading-6 ${className ? className : ""}`}>{children}</p>;
};

export default Paragraph;
