import React, { ReactNode } from "react";

interface ContainerProps {
  children: ReactNode;
  className?: string;
  variant?: "md" | "lg";
}
const Container = ({ className, variant = "md", children }: ContainerProps) => {
  return (
    <div
      className={`${
        variant === "md" ? "max-w-[1060px]" : "max-w-[1250px]"
      } mx-auto ${className ? className : ""}`}
    >
      {children}
    </div>
  );
};

export default Container;
