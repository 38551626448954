import React from "react";
import Heading from "../../Heading";
import { Section } from "../../layout";

interface CardProps {
  title: string;
  description: string;
  iconPath: string;
  textColor: string;
  bgColor: string;
}

const Card = ({
  title,
  description,
  iconPath,
  textColor,
  bgColor,
}: CardProps) => {
  return (
    <div
      className={`rounded-2xl py-12 px-[40px] lg:px-[72px]`}
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      <p className="leading-[28px]">{description}</p>
      <img className="mx-auto my-12" src={iconPath} alt={title} />
      <p className="text-[24px] font-bold leading-[28px]">{title}</p>
    </div>
  );
};

const WhatWeOffer = () => {
  const cards = [
    {
      title: "Real Estate Investment Consultancy.",
      description: "Strategic advice to maximize return on investment.",
      iconPath: "/svgs/Stock.svg",
      textColor: "#1F8FFF",
      bgColor: "#E3F1FF",
    },
    {
        title: "Real Estate Portfolio Management.",
        description: "Active management of properties to yield maximum returns..",
        iconPath: "/svgs/Portfolio.svg",
        textColor: "#475DFF",
        bgColor: "#E4E7FF",
      },
      {
        title: "Facility and Estate Management.",
        description: "Through 10ANTS NG, we provide comprehensive facility management services.",
        iconPath: "/svgs/House.svg",
        textColor: "#E0B567",
        bgColor: "#F9F1E2",
      },
      {
        title: "Property Law Services.",
        description: "Expertise in navigating complex legal aspects of real estate investments.",
        iconPath: "/svgs/Arrow.svg",
        textColor: "#729B79",
        bgColor: "#F3F7F4",
      },
  ];
  return (
    <Section className="py-16">
      <Heading className="text-center" variant="lg">
        What we offer
      </Heading>
      <div className="grid sm:grid-cols-2 gap-6 max-w-[894px] mx-auto mt-8" id="services">
        {cards.map((card, i) => (
          <Card key={i} {...card} />
        ))}
      </div>
    </Section>
  );
};

export default WhatWeOffer;
