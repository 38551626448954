import React, { useState } from "react";
import Container from "../../layout/Container";
import Heading from "../../Heading";
import { Section } from "../../layout";

const KeyFactors = () => {
  const factorsArr = [
    {
      title: "Desire",
      text: "We aspire to be the premier choice for real estate investors seeking unparalleled expertise and personalized portfolio management. Our vision is to cultivate lasting wealth and security for our clients by consistently identifying and capitalizing on high-potential investment opportunities. We aim to set the standard for excellence in the industry through innovative strategies, unwavering integrity, and a relentless focus on our clients' success.",
    },
    {
      title: "Responsibility",
      text: "We aspire to be the premier choice for real estate investors seeking unparalleled expertise and personalized portfolio management. Our vision is to cultivate lasting wealth and security for our clients by consistently identifying and capitalizing on high-potential investment opportunities. We aim to set the standard for excellence in the industry through innovative strategies, unwavering integrity, and a relentless focus on our clients' success.",
    },
    {
      title: "Relations",
      text: "We aspire to be the premier choice for real estate investors seeking unparalleled expertise and personalized portfolio management. Our vision is to cultivate lasting wealth and security for our clients by consistently identifying and capitalizing on high-potential investment opportunities. We aim to set the standard for excellence in the industry through innovative strategies, unwavering integrity, and a relentless focus on our clients' success.",
    },
    {
      title: "Creative",
      text: "We aspire to be the premier choice for real estate investors seeking unparalleled expertise and personalized portfolio management. Our vision is to cultivate lasting wealth and security for our clients by consistently identifying and capitalizing on high-potential investment opportunities. We aim to set the standard for excellence in the industry through innovative strategies, unwavering integrity, and a relentless focus on our clients' success.",
    },
  ];

  const [currentFactorIndex, setCurrentFactorIndex] = useState<number>(0);

  const scrollTextIntoView = (index: number) => {
    setCurrentFactorIndex(index);
  };

  return (
    <Section className="py-16">
      <Container>
        <Heading variant="lg" className="mb-14 text-center">
          Key Factors
        </Heading>
        <div className="grid gap-8 md:grid-cols-2 md:gap-0 items-start">
          <div>
            {factorsArr.map(({ title }, index) => (
              <div
                key={index}
                className="flex gap-5 items-center text-2xl font-medium [&:not(:last-child)]:mb-[54px]"
                onClick={() => scrollTextIntoView(index)}
              >
                <div
                  className={`w-7 h-7 rounded-full border-4 duration-300 ${
                    currentFactorIndex >= index
                      ? "border-[#0361CC]"
                      : "border-[#C0C0C0]"
                  } relative before:relative before:block before:w-1 before:h-[66px] before:mt-5 before:left-1/2 before:translate-x-[-50%] ${
                    currentFactorIndex >= index
                      ? "before:bg-[#0361CC]"
                      : "before:bg-[#C0C0C0]"
                  }        
                          ${index === 3 ? "before:hidden" : ""}
                          
                          
                          after:relative after:block after:w-1 after:h-[34px] after:mt-[-35px] after:left-1/2 after:translate-x-[-50%] ${
                            currentFactorIndex > index ||
                            currentFactorIndex === factorsArr.length - 1
                              ? "after:bg-[#0361CC]"
                              : "after:bg-[#C0C0C0]"
                          }        
   `}
                />

                <p
                  className={
                    currentFactorIndex >= index ? "" : "text-[#C0C0C0]"
                  }
                >
                  {title}
                </p>
              </div>
            ))}
          </div>
          <div className="leading-8 overflow-hidden duration-300 grid relative">
              <div
                // key={index}
                //ref={(el) => (textRefs.current[index] = el)}
                className={`transition-all duration-300 ease-in-out grid overflow-hidden grid-rows-[1fr]`}
              >
                <p
                  className={`overflow-hidden transition-all duration-300 ease-in-out`}
                >
                  {factorsArr[0].text}
                </p>
              </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default KeyFactors;
