import React, { useState } from "react";
import Heading from "../../Heading";
import Container from "../../layout/Container";
import { Section } from "../../layout";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./team-carousel.css";

const TeamSection = () => {
  const [TeamMember, setTeamMember] = useState(1);
  return (
    <Section className="py-16">
      <Container>
        <Heading className="text-center mb-16" variant="lg">
          Meet our Team
        </Heading>
        <div className="flex flex-col lg:gap-20 lg:flex-row lg:justify-between">
          <div className="lg:max-w-[551px]">
            {/* <p className="leading-7">
              Corem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus. Class aptent taciti sociosqu ad litora torquent
              per conubia nostra, per inceptos himenaeos. Praesent auctor purus
              luctus enim egestas, ac scelerisque ante pulvinar. Donec ut
              rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur
              vel bibendum lorem. Morbi convallis convallis diam sit amet
              lacinia. Aliquam in elementum tellus.
            </p> */}
            <div className=" mt-[100px] lg:mt-[240px]">
              <p className="font-semibold text-lg lg:text-2xl">
                {TeamMember === 1 ? 'DAMILOLA FASHOLA' : TeamMember === 2 ? 'ADEMIMI ADEWUIYI' : 'AKINDELE OLUSEGUN'}
              </p>
              <p className="text-sm lg:text-xl mt-2">
                {TeamMember === 1 ? 'Director / Head of Legal' : TeamMember === 2 ? 'Director/ Company Growth Director' : 'Chief Facility Officer'}
              </p>
            </div>
          </div>
          <div className="team-carousel mt-10 lg:mt-0">
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              onSwiper={(swiper: any) => {
              }}
              onSlideChange={(swiper: any) => {
                console.log('====================================');
                console.log(swiper.previousIndex);
                console.log('====================================');
                if(swiper.previousIndex < swiper.activeIndex) {
                  return setTeamMember(prevState => prevState + 1);
                } else {
                  return setTeamMember(prevState => prevState - 1);
                }
              }}
            >
              <SwiperSlide>
                <img src="/images/First.png " alt="DAMILOLA FASHOLA" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="https://images.unsplash.com/photo-1607743882420-4412ee605bac?q=80&w=2835&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="ADEMIMI ADEWUIYI" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="/images/Third.png" alt="AKINDELE OLUSEGUN" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default TeamSection;
