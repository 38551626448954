import React from "react";

interface HeadingProps {
  variant?: "md" | "lg";
  className?: string;
  children: any;
}

const Heading = ({ variant = "md", className, children }: HeadingProps) => {
  return (
    <h3
      className={`${
        variant === "md"
          ? "text-[26px] lg:text-[32px]"
          : "text-[32px] lg:text-[40px]"
      } font-semibold ${className ? className : ""}`}
    >
      {children}
    </h3>
  );
};

export default Heading;
