export const listingsData = [
    {
      name:'Sandton',
      location:'South Africa',
      price:'$398',
      image:'https://res.cloudinary.com/welkom-u/image/upload/v1731893862/10Ants/zllfdz2s5y2slcxnv7kf.png',
      bedrooms:'1',
      bathrooms:'2',
      country: 'South Africa'
    },
    {
        name:'Blue Water',
        location:'Lagos',
        price:'$175',
        image: 'https://res.cloudinary.com/welkom-u/image/upload/v1731893199/10Ants/xishomf0stkhz5hkhagb.jpg',
        bedrooms:'3',
        bathrooms:'3',
        country: 'Lagos'
    },
    {
        name:'Metropolis',
        location:'Lagos',
        price:'$160',
        image: 'https://res.cloudinary.com/welkom-u/image/upload/v1731894054/10Ants/xsw065paxsu2apf0rgnm.jpg',
        bedrooms:'2',
        bathrooms:'2',
        country: 'Lagos'
    },
    {
        name:'Periwinkle Residences',
        location:'Lekki Lagos',
        price:'$130',
        image: 'https://res.cloudinary.com/welkom-u/image/upload/v1731894149/10Ants/ktl6ojrz9jfagbeqendy.jpg',
        bedrooms:'2',
        bathrooms:'3',
        country: 'Lagos'
    },
    {
        name:'HOK Apartments',
        location:'Lagos',
        price:'$130',
        image: 'https://res.cloudinary.com/welkom-u/image/upload/v1731894264/10Ants/kaezutxesgcszt82nidm.jpg',
        bedrooms:'4',
        bathrooms:'6',
        country: 'Lagos'
    },
    {
      name:'Signature Residences',
      location:'Lekki Phase one Lagos',
      price:'$150',
      image:'https://res.cloudinary.com/welkom-u/image/upload/v1731893441/10Ants/jc01dizxjiwjthnfxo8g.jpg',
      bedrooms:'2',
      bathrooms:'3',
      country: 'Lagos'
    },
    {
      name:'Lekki Luxury Flats',
      location:'Lekki Phase one Lagos',
      price:'$150',
      image:'https://res.cloudinary.com/welkom-u/image/upload/v1731893403/10Ants/lwpw6ksfcpek1xvyyhaf.jpg',
      bedrooms:'3',
      bathrooms:'2',
      country: 'Lagos'
    },
    {
      name:'The Lofts Apartments',
      location:'Lekki Lagos',
      price:'$150',
      image:'https://res.cloudinary.com/welkom-u/image/upload/v1731893504/10Ants/k5yt1jnwwotxcxfn6kvf.jpg',
      bedrooms:'1',
      bathrooms:'2',
      country: 'Lagos'
    },
    {
      name:'',
      location:'London E14',
      price:'£1200',
      image:'https://res.cloudinary.com/welkom-u/image/upload/v1731893746/10Ants/pioh8ydimcidguai36qn.png',
      bedrooms:'5',
      bathrooms:'3',
      country: 'London',
      rate: 'month'
    },
    {
      name:'',
      location:'East London',
      price:'£1200',
      image:'https://res.cloudinary.com/welkom-u/image/upload/v1731893693/10Ants/hlwsygrunygqqly0xesv.png',
      bedrooms:'5',
      bathrooms:'3',
      country: 'London',
      rate: 'month'
    },
  ];

export const galleryImages = [
    "https://res.cloudinary.com/welkom-u/image/upload/v1731896175/PHOTO-2024-10-03-21-25-11_an6c85.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731896306/PHOTO-2024-10-03-21-08-50_5_u7esq9.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731896179/PHOTO-2024-10-03-21-25-16_xnrrm2.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731896180/PHOTO-2024-10-03-21-25-18_goshpz.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1732278659/DSC09098_wzq43d.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1732278658/DSC09091_ursqdu.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1732278657/DSC09026_s2excs.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1732278657/DSC08987_oakaa7.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1732278658/DSC09065_u7fs4p.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1732278658/DSC09058_rp5jgm.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731893746/10Ants/pioh8ydimcidguai36qn.png",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731893862/10Ants/zllfdz2s5y2slcxnv7kf.png",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731893199/10Ants/xishomf0stkhz5hkhagb.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731894054/10Ants/xsw065paxsu2apf0rgnm.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731894149/10Ants/ktl6ojrz9jfagbeqendy.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731896182/PHOTO-2024-10-03-21-25-21_azd1ta.jpg",
    "https://res.cloudinary.com/welkom-u/image/upload/v1731896182/PHOTO-2024-10-03-21-25-20_2_uufz2h.jpg",
];