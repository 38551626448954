import React, { ReactNode } from "react";

interface HeaderProps {
  children: ReactNode;
  className?: string;
  style?: any;
}

const Header = ({
  children,
  className,
  style
}: HeaderProps) => {
  return (
    <header
      className={`header px-6 ${className ? className : ""}`}
      style={style}
    >
      {children}
    </header>
  );
};

export default Header;
