import React, { useState } from "react";
import Container from "../../layout/Container";
import Heading from "../../Heading";

import { PiMapPinArea, PiDotBold } from "react-icons/pi";
import Button from "../../Button";
import Select from "../../Select";
import { Section } from "../../layout";
import { listingsData } from "../../../data";


const FilterCategory = ({ filterTitle, children }: any) => {
  return (
    <div className="border border-[#EBEBEB] px-8 py-7 rounded-lg h-fit text-sm">
      <p className="font-semibold mb-6 text-base">{filterTitle}</p>
      {children}
    </div>
  );
};

const ListingCard = ({item}:any) => {
  return (
    <div className="">
      <img className="h-[330px] w-[411px] object-cover rounded-t-lg" src={item.image} alt="placeholder" />
      <div className="p-4 rounded-b-lg border border-[#EBEBEB]">
        <Heading className="inline">{item.price}</Heading>
        <p className="inline font-medium mt-[-4px]">/per {item.rate? item.rate :'night'}</p>
        <div className="flex items-center gap-2 mt-4">
          <PiMapPinArea size={24} />
          <p>{item.name}{item.country !== 'London' && ','} {item.location}</p>
        </div>
        <div className="flex items-center mt-4">
          {item.bedrooms} Bedrooms <PiDotBold size={40} />{item.bathrooms} Bathrooms
        </div>
      </div>
    </div>
  );
};

const Listings = () => {
  const [Filter, setFilter] = useState('');
  const handleSelect = (item:string) => {
    if(Filter == item){
      setFilter('');
    } else {
      setFilter(item)
    }
  }
  return (
    <Section className="py-10">
      <Container variant="lg" className="lg:flex lg:gap-[75px]">
        <div className="lg:w-[360px] flex flex-col gap-6">
          <FilterCategory filterTitle="Sort By">
            <div className="grid gap-4">
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded checked:bg-[#0361CC]"
                  checked={Filter == 'Lagos'}
                  onChange={()=>handleSelect('Lagos')}
                />
                Lagos
              </div>
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded checked:bg-[#0361CC]"
                  checked={Filter == 'South Africa'}
                  onChange={()=>handleSelect('South Africa')}
                />
                South Africa
              </div>
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded checked:bg-[#0361CC]"
                  checked={Filter == 'London'}
                  onChange={()=>handleSelect('London')}
                />
                London
              </div>
            </div>
          </FilterCategory>
          <FilterCategory filterTitle="Price Range">
            <div className="grid gap-4">
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded checked:bg-[#0361CC]"
                />
                &lt; ₦70,000
              </div>
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded checked:bg-[#0361CC]"
                />
                ₦80,000 - ₦100,000
              </div>
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded checked:bg-[#0361CC]"
                />
                ₦120,000 - ₦150,000
              </div>
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded checked:bg-[#0361CC]"
                />
                ₦150,000+
              </div>
            </div>
          </FilterCategory>
          <FilterCategory filterTitle="Refine Results">
            <div className="grid gap-4">
              <Select placeholder="Bedrooms" />
              <Select placeholder="Bathrooms" />
              <Select placeholder="Pools" />
            </div>
            <Button className="mt-6" variant="sm">
              Clear
            </Button>
          </FilterCategory>
        </div>
        <div className="grid lg:grid-cols-2 gap-6">
          {!Filter ? listingsData.map(item => {
            return (
              <ListingCard item={item} />
            )
          }) : listingsData.filter(items => items.country == Filter).map(item => {
            return (
              <ListingCard item={item} />
            )
          })}
        </div>
      </Container>
    </Section>
  );
};

export default Listings;
