import React from "react";
import Heading from "../../Heading";
import Paragraph from "../../Paragraph";


import { Section } from "../../layout";
import PortfolioCarousel from "./PorfolioCarousel";
import NewCarousel from "./NewCarousel";

export default function Portfolio() {

  return (
    <Section className="py-8">
      <div className="bg-[#EFF3FC] text-sm px-6 py-2 rounded-3xl w-fit mx-auto mb-1">
        Portfolio
      </div>
      <Heading className="text-center mb-2">Our Portfolio</Heading>
      <Paragraph className="text-center max-w-[600px] mx-auto mb-10 text-[#3F4253]">
        A diverse range of high-value real estate investments, from luxury
        apartments to exclusive residential estates
      </Paragraph>
      {/* <PortfolioCarousel /> */}
      <NewCarousel/>
    </Section>
  );
}
