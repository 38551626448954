import React from "react";

interface TextareaProps {
  className?: string;
  placeholder?: string;
  value?: string;
  label?: string;
  rows?: number;
  name: string;
  onChange: (e:string,t:string)=>void;
}

const Textarea = ({ className, label, placeholder, rows = 5, name, value,onChange }: TextareaProps) => {
  return (
    <div className={`${className ? className : ""}`}>
      {label && <label className="block mb-2 font-medium">{label}</label>}
      <textarea
        name={name}
        className="border border-[#EBEBEB] min-h-[165px] w-full h-12 px-3 py-2 rounded-lg placeholder:text-sm focus:outline-none resize-none"
        placeholder={placeholder}
        value={value}
        onChange={(e)=>onChange(e.target.value, name)}
        rows={7}
      />
    </div>
  );
};

export default Textarea;
