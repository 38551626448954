import React, { useEffect, useState } from "react";
import { useForm, ValidationError } from '@formspree/react';
import { Button, Container, Heading, Input, Textarea } from "../../components";
import { Section } from "../../components/layout" ;
import Loader from "../../components/loader";

const ContactUs = () => {
  const [state, handleSubmit] = useForm("mldewqpo");
  const [contactData, setContactData] = useState({
    title: '',
    email: '',
    name: '',
    phoneNumber: '',
    message: '',
  });

  const handleChange = (text:string,title:string) => {
    setContactData(prevState => {return {...prevState,[title]: text}})
  }

  useEffect(() => {
    if(state.succeeded){
      setContactData({
        title: '',
        email: '',
        name: '',
        phoneNumber: '',
        message: '',
      })
    }
  }, [state])
  
  return (
    <Section className="pt-40 px-6">
      <Heading className="text-center mb-[60px]">Contact Us</Heading>
      <Container className="grid md:grid-cols-2">
        <div>
          {" "}
          <div className="mb-16">
            <p className="font-bold mb-2">Contact us</p>
            <p>
              Our team is here to help you<br />
              <span><a href="mailto:contact@jazzrealtiesltd.com">contact@jazzrealtiesltd.com</a></span>
            </p>
          </div>
          <div>
            <p className="font-bold mb-2">Call us</p>
            <p>
              Mon - Fri from 10am to 6pm <br />
              +44 77 6996 6948 +234 807 143 9941
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-6">
            <div>
              <Input name='title' label="Title" placeholder="Select an option" value={contactData.title} onChange={(e)=>handleChange(e,'title')} />

              <ValidationError 
                prefix="Title" 
                field="title"
                errors={state.errors}
              />
            </div>
            <div>
              <Input name='name' label="Full name" placeholder="Input full name" value={contactData.name} onChange={(e)=>handleChange(e,'name')} />

              <ValidationError 
                prefix="Name" 
                field="name"
                errors={state.errors}
              />
            </div>
            <div>
              <Input name='email' label="Email address" placeholder="Input email address" value={contactData.email} onChange={(e)=>handleChange(e,'email')} />

              <ValidationError 
                prefix="Email" 
                field="email"
                errors={state.errors}
              />
            </div>
            <div>
              <Input name='phoneNumber' label="Phone number" placeholder="Input phone number" value={contactData.phoneNumber} onChange={(e)=>handleChange(e,'phoneNumber')} />

              <ValidationError 
                prefix="PhoneNumber" 
                field="phoneNumber"
                errors={state.errors}
              />
            </div>
            <div>
              <Textarea name='message' label="Message" placeholder="Tell us how we can help you..." value={contactData.message} onChange={(e)=>handleChange(e,'message')}/>

              <ValidationError 
                prefix="Message" 
                field="message"
                errors={state.errors}
              />
            </div>
          </div>
          {state.submitting ? (
            <button className="py-3 px-10 text-xs rounded-[38px] font-semibold bg-white justify-center flex ml-auto mt-6 border border-[#0361CC] w-[183px] h-14">
              <Loader/>
            </button>
          ): (
            <Button className="block ml-auto mt-6">Submit</Button>
          )}
        </form>
      </Container>
    </Section>
  );
};

export default ContactUs;
