import React from "react";
import { Section } from "../../layout";

const InsightsSection = () => {
  const insights = [
    { amount: "10+", description: "premium estates under management" },
    {
      amount: "11%+",
      description: "average annual return on investment properties",
    },
    { amount: "50+", description: "units across residential  properties" },
    {
      amount: "£4 million",
      description: "in real estate assets under management",
    },
  ];
  return (
    <Section
      className="section bg-[#0361CC] py-16 text-white text-center"
      data-navbar-color="light"
    >
      <div className="grid grid-cols-2 max-w-[462px] mx-auto">
        {insights.map(({ amount, description }, i) => (
          <div
            className={`${
              i === 0 || i === 1 ? "border-b pt-10 pb-6" : "pt-6 pb-10"
            } ${
              i === 0 || i === 2 ? "border-r" : ""
            } border-dashed border-white px-6`}
            key={i}
          >
            <p className="text-2xl lg:text-[40px] font-semibold mb-2">{amount}</p>
            <p className="text-sm lg:leading-[22px]">{description}</p>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default InsightsSection;
