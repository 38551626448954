import React, { ReactNode } from "react";

interface SectionProps {
  children: ReactNode;
  className?: string;
}

const Section = ({
  children,
  className,
}: SectionProps) => {
  return (
    <section
      className={`section px-6 ${className ? className : ""}`}
    >
      {children}
    </section>
  );
};

export default Section;
