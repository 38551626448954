import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./image-carousel.css";
import { galleryImages } from "../../data";

export default function ImageCarousel({video}:any) {
  return (
    <div className="carousel-container w-full max-w-[1218px] mx-auto">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper: any) => {}}
        onSlideChange={() => {}}
      >
        {
          video && (
            <>
              <SwiperSlide>
                <video
                  controls
                  muted
                  preload="auto"
                  loop
                  autoPlay
                  className="rounded-3xl w-full max-w-[1060px] object-cover mx-auto"
                  src="https://res.cloudinary.com/welkom-u/video/upload/v1731895076/36c1e9de-7fc6-4988-afde-8bddc3ea3658_py0rsp.mp4"
                >
                  <source src="movie.mp4" type="video/mp4"/>
                </video>
              </SwiperSlide>
              <SwiperSlide>
                <video
                  controls
                  muted
                  preload="auto"
                  loop
                  autoPlay
                  className="rounded-3xl w-full max-w-[1060px] object-cover mx-auto"
                  src="https://res.cloudinary.com/welkom-u/video/upload/v1731897034/10ants_ad_4_xl5tiu.mp4"
                >
                  <source src="movie.mp4" type="video/mp4"/>
                </video>
              </SwiperSlide>
              <SwiperSlide>
                <video 
                  controls
                  muted
                  preload="auto"
                  loop
                  autoPlay
                  className="rounded-3xl object-cover mx-auto"
                  src="https://res.cloudinary.com/welkom-u/video/upload/v1732278691/RPReplay_Final1701765588_lhlhtz.mov"
                >
                  <source src="movie.mp4" type="video/mp4"/>
                </video>
              </SwiperSlide>
            </>
          )
        }
        {!video && galleryImages.map((item, i) => {
          return (
            <SwiperSlide key={i}>
              <img
                className="rounded-3xl w-full max-w-[1060px] h-[498px] mx-auto object-cover"
                src={item}
                alt="placeholder"
              />
            </SwiperSlide>
        )})}
      </Swiper>
    </div>
  );
}
