import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CiCirclePlus, CiCircleMinus } from "react-icons/ci";
import Container from "../../layout/Container";
import Heading from "../../Heading";

const FaqAccordion = () => {
  const questions = [
    {
      question: "What services does J.R. LTD offer?",
      answer:
        "We offer a comprehensive range of services including real estate investment consultancy, portfolio management, facility and estate management through our subsidiary 10ANTS NG, and property law services.",
    },
    {
      question:
        "How do you help clients maximise returns on real estate investments?",
      answer:
        "We advise on the best investment strategies—buy-to-hold, annual rent, or short-term rentals—based on market trends and client goals. We manage the property, find tenants or buyers, and send monthly reports to ensure transparent performance tracking.",
    },
    {
      question: "Can you manage properties that I've already purchased?",
      answer:
        "Yes, we provide full management services for properties you've already bought. We’ll maximize your returns through strategic management, listing the property for sale, long-term lease, or short-term rentals as needed.",
    },
    {
      question: "How do you find tenants or buyers for my property?",
      answer:
        "We utilize a combination of marketing strategies, real estate platforms, and our extensive network to find qualified tenants or buyers. Our goal is to quickly and efficiently match your property with suitable candidates to maximize occupancy or resale value.",
    },
    {
      question: "What type of reports do I receive as a client?",
      answer:
        "We provide monthly reports detailing the performance of your real estate portfolio. This includes rental income, occupancy rates, property condition updates, and market insights to help you stay informed about your investment.",
    },
    {
      question: "Can you help with legal matters related to my property?",
      answer:
        "Yes, our property law department, headed by an experienced lawyer with a Master’s in Law, handles all property law matters. We can assist with contracts, disputes, tenant agreements, and any legal issues tied to your property.",
    },
    {
      question:
        "What's the difference between short-termand long-term rentals?",
      answer:
        "Short-term rentals are typically for vacationers or business travelers who stay for a few days to weeks, while long-term rentals involve leases of six months to a year. Each has its benefits, and we can help determine the best option based on market conditions and your financial goals.",
    },
    {
      question:
        "What is the minimum investment required to work with your firm?",
      answer:
        "We tailor our services to each client's budget and goals. Whether you're looking to invest in smaller residential properties or large-scale commercial real estate, we can advise on the best opportunities to suit your financial situation.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(-1);

  return (
    <div className="grow relative">
      {questions.map(({ question, answer }, index) => (
        <div
          key={index}
          className="[&:not(:last-child)]:border-b border-dashed border-[#C7C5C5] bg-white "
        >
          <div
            className="flex justify-between items-center cursor-pointer py-8"
            onClick={() =>
              currentIndex === index
                ? setCurrentIndex(-1)
                : setCurrentIndex(index)
            }
          >
            <p className="text-xl">{question}</p>
            <CiCircleMinus size={24} color="#0D0D0D" style={{display: currentIndex === index ? "block": "none"}} />
            <CiCirclePlus size={24} color="#0D0D0D" style={{display: currentIndex === index ? "none": "block"}}   />
          </div>
          <div
            className={`transition-all duration-300 ease-in-out grid overflow-hidden ${
              currentIndex === index
                ? "grid-rows-[1fr] opacity-100"
                : "grid-rows-[0fr] opacity-0"
            }`}
          >
            <p
              className={`overflow-hidden transition-all duration-300 ease-in-out opacity-60 ${
                currentIndex === index ? "mb-6" : ""
              }`}
            >
              {answer}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const FAQ = () => {
  return (
    <section className="px-6 py-16">
      <Container variant="lg" className="lg:flex lg:gap-[192px]">
        <div className="max-w-[268px] shrink-0 mb-10 lg:mb-0">
          <div className="text-sm bg-[#EFF3FC] rounded-[32px] py-2 px-6 w-fit mb-2">
            FAQs
          </div>
          <Heading className="mb-8 leading-10">
            Frequently Asked Questions
          </Heading>
          <Link
            to="/contact"
            className="border border-[#0361CC] text-[#0361CC] px-8 py-4 rounded-[32px]"
          >
            Contact Us
          </Link>
        </div>
        <FaqAccordion />
      </Container>
    </section>
  );
};

export default FAQ;
